import { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import Hashids from 'hashids';
import moment from 'moment-timezone';
import { CompleteEvent, FunctionFactory, Model, surveyLocalization, Serializer } from 'survey-core';
import { Survey as SurveyComponent } from 'survey-react-ui';
import { Skeleton, Button } from '@ford/ford-ui-components';

import { useShowContext } from '@/contexts/ShowContext';
import { useLanguage } from '@/contexts/LanguageContext';
import { themeJSON as fordTheme } from '@/themes/surveyJS/ford';
import { themeJSON as lincolnTheme } from '@/themes/surveyJS/lincoln';
import { initSurvey, prepareForSurvey, prepareSurveyOnQuestionAdded } from "@/helpers/surveyTemplatesAll";
import { initSurveyFord } from '@/helpers/surveyTemplatesFord';
import { initSurveyLincoln } from '@/helpers/surveyTemplatesLincoln';

// these are the custom surveyjs renderers
// while not referenced in the code they have to be imported to be registered
import { CheckboxVOIQuestion } from "@/components/surveysjs_renderers/CheckboxVOI";
import { RadioGroupRowQuestion } from "@/components/surveysjs_renderers/RadioButtonButton";
import { EmailTextInput } from "@/components/surveysjs_renderers/EmailTextInput";
import '@/components/surveysjs_renderers/CheckboxVOITabs';
import '@/components/surveysjs_renderers/CheckboxVOITabsCA';

import "survey-core/defaultV2.min.css";
import "@/styles/survey-overrides.scss";

import fordUS from './ford_us.json';
import fordCA from './ford_ca.json';
import lincolnUS from './lincoln_us.json';
import lincolnCA from './lincoln_ca.json';

// Import background images
import nautilus2160 from '@/assets/images/25nautilus.web.2160.2160.jpg';
import nautilus960 from '@/assets/images/25nautilus.web.960.960.jpg';
import expedition2160 from '@/assets/images/25expedition.web.2160.2160.jpg';
import expedition960 from '@/assets/images/25expedition.web.960.960.jpg';

import { v4 as uuidv4 } from 'uuid';
import surveyService from '@/services/surveyService';
import { useTranslation } from '@/hooks/useTranslation';
import waiverJSON from './waivers.json';
import { AxiosError } from 'axios';

// Register the custom property BEFORE any other survey-related imports
Serializer.addProperty("autocompleteaddress", {
  name: "country",
  default: "us",
  category: "general",
  visibleIndex: 3
});

initSurveyFord();
initSurveyLincoln();
initSurvey();

// Helper function to convert string errors to AxiosError format
const createError = (message: string): AxiosError => {
  return new AxiosError(
    message,
    'ERR_CUSTOM',
    undefined,
    undefined,
    undefined
  );
};

function validateEmail(this: {
  question: {
    value: string;
    setPropertyValue: (key: string, value: string) => void;
    didYouMean?: string;
  };
  survey: any;
  returnResult: (valid?: boolean) => void
}) {
  console.log('[validateEmail]', this.question.value);
  const email = this.question.value;

  this.question.setPropertyValue('didYouMean', "");

  if (!email) {
    this.returnResult();
    return;
  }

  fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/validateEmail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  }).then(response => {
    response.json().then((res) => {
      const { results } = res;

      let valid = true;
      console.log('this.survey', this.survey);
      console.log('this.question', this.question);
      console.log('validateEmail res', res);

      // bad emails are rejected
      if (results?.valid === false) {
        valid = false;
      }

      // disposable email services are rejected
      if (results?.is_disposable === true) {
        valid = false;
      }

      // reject delivery_confidence below 20
      if (results?.delivery_confidence < 20) {
        valid = false;
      }

      // typos are rejected with correction
      if (results.did_you_mean) {
        valid = true;
        this.question.setPropertyValue('didYouMean', results.did_you_mean);

        console.log('this.question after', this.question.didYouMean);
      }

      this.returnResult(valid);
    });
  }).catch(err => {
    // Convert string error to AxiosError
    const axiosError = createError(err.toString());
    alert(axiosError.message);
  });
}

FunctionFactory.Instance.register("validateEmail", validateEmail, true);

const extractUTM = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const utm: Record<string, string> = {};

  for (const [key, value] of params.entries()) {
    if (["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"].includes(key)) {
      utm[key] = value;
    }
  }

  return utm;
}

const requiredWaiverShowTypes = ['broncoMountainRegistration', 'rideDriveRegistration', 'simulatorRegistration', 'evRideRegistration'];

const SurveySkeleton = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      {/* Logo */}
      <div className="mb-8">
        <Skeleton className="h-8 w-24" />
      </div>

      {/* Title */}
      <div className="mb-8">
        <Skeleton className="h-8 w-64" />
      </div>

      {/* Form Fields */}
      <div className="space-y-6">
        {/* Name Fields */}
        <div className="space-y-6 md:space-y-6">
          <div className="space-y-2">
            <Skeleton className="h-4 w-20" /> {/* Label */}
            <Skeleton className="h-12 w-full" /> {/* Input */}
          </div>
          <div className="space-y-2">
            <Skeleton className="h-4 w-20" />
            <Skeleton className="h-12 w-full" />
          </div>
        </div>

        {/* Address Fields */}
        <div className="space-y-6">
          <div className="space-y-2">
            <div className="flex items-center gap-1">
              <Skeleton className="h-4 w-24" />
              <Skeleton className="h-4 w-2" /> {/* Required asterisk */}
            </div>
            <Skeleton className="h-12 w-full" />
          </div>
          <div className="space-y-2">
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-12 w-full" />
          </div>
        </div>

        {/* City, State, Zip */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="space-y-2">
            <div className="flex items-center gap-1">
              <Skeleton className="h-4 w-8" />
              <Skeleton className="h-4 w-2" />
            </div>
            <Skeleton className="h-12 w-full" />
          </div>
          <div className="space-y-2">
            <div className="flex items-center gap-1">
              <Skeleton className="h-4 w-12" />
              <Skeleton className="h-4 w-2" />
            </div>
            <Skeleton className="h-12 w-full" />
          </div>
          <div className="space-y-2">
            <div className="flex items-center gap-1">
              <Skeleton className="h-4 w-16" />
              <Skeleton className="h-4 w-2" />
            </div>
            <Skeleton className="h-12 w-full" />
          </div>
        </div>

        {/* Email */}
        <div className="space-y-2">
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-12 w-full" />
        </div>

        {/* Mobile */}
        <div className="space-y-2">
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-12 w-full" />
          <Skeleton className="h-4 w-64" /> {/* Standard message text */}
        </div>

        {/* Email Communications */}
        <div className="space-y-4">
          <div className="flex items-center gap-1">
            <Skeleton className="h-4 w-full max-w-lg" />
            <Skeleton className="h-4 w-2" />
          </div>
          <div className="flex gap-4">
            <Skeleton className="h-12 w-24" />
            <Skeleton className="h-12 w-24" />
          </div>
          <Skeleton className="h-4 w-full max-w-lg" /> {/* Privacy policy text */}
        </div>
      </div>
    </div>
  );
};

const Survey = () => {
  const { hashed_show_id, specialist_id, timestamp } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { language, setLanguage } = useLanguage();
  const [thisSurvey, setThisSurvey] = useState<Model>();
  const source = searchParams.get('t') || 'microsite';
  const { showData, loading, error } = useShowContext();
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useTranslation();
  const [resolvedConfig, setResolvedConfig] = useState<any>(null);

  // Handle language selection based on priority
  useEffect(() => {
    if (!showData) return;

    const availableLanguages = showData.isCanadian ? ['en', 'fr'] : ['en', 'es'];

    // Priority 1: URL parameter (only if it's a supported language for this show type)
    const urlLang = searchParams.get('lang');
    if (urlLang && availableLanguages.includes(urlLang)) {
      setLanguage(urlLang as 'en' | 'es' | 'fr');
      return;
    }

    // Priority 2: Browser language (only if it's a supported language for this show type)
    const browserLang = navigator.language.split('-')[0];
    if (browserLang && availableLanguages.includes(browserLang)) {
      setLanguage(browserLang as 'en' | 'es' | 'fr');
      return;
    }

    // Priority 3: Default to English
    setLanguage('en');
  }, [searchParams, showData, setLanguage]);

  surveyLocalization.defaultLocale = "en";

  // Decode and validate QR timestamp
  const hasher = new Hashids('stupid scammers');
  const [ts] = hasher.decode(timestamp!);
  const qrTime = moment(Number(ts));

  // Create memoized survey config outside initSurvey
  const surveyConfig = useMemo(async () => {
    if (!showData) return null;

    // Set supported locales
    if (showData.isCanadian) {
      surveyLocalization.supportedLocales = ["en", "fr"];
      surveyLocalization.locales["fr"] = {
        pagePrevText: "Précédent",
        pageNextText: "Suivant",
        completeText: "Envoyer",
        requiredError: "Obligatoires",
        invalidEmail: "Veuillez entrer une adresse e-mail valide.",
        textMinLength: "Entrez au moins {0} caractères.",
        textMaxLength: "N'entrez pas plus de {0} caractères.",
        invalidFormat: "Format non valide."
      };
    } else {
      surveyLocalization.supportedLocales = ["en", "es"];
      surveyLocalization.locales["es"] = {
        pagePrevText: "Anterior",
        pageNextText: "Siguiente",
        completeText: "Enviar",
        requiredError: "Obligatorio",
        invalidEmail: "Por favor ingrese un correo electrónico válido",
        textMinLength: "Por favor ingrese al menos {0} caracteres",
        textMaxLength: "Por favor ingrese no más de {0} caracteres",
        invalidFormat: "Formato no válido"
      };
    }

    // Initialize theme
    if (showData.isLincoln) {
      await import('@/helpers/surveyTemplatesLincoln');
    } else {
      await import('@/helpers/surveyTemplatesFord');
    }

    const themeJSON = showData.isLincoln ? lincolnTheme : fordTheme;

    // Set document properties
    document.body.style.backgroundColor = themeJSON.cssVariables?.['--sjs-editor-background'] || '#ffffff';
    document.title = showData.showName;

    if (themeJSON.favicon) {
      const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
      if (link) {
        link.href = themeJSON.favicon;
      }
    }

    // Create survey config
    const surveyJSON = showData.isCanadian
      ? (showData.isLincoln ? lincolnCA : fordCA)
      : (showData.isLincoln ? lincolnUS : fordUS);
      
    const config = { ...surveyJSON };

    if (showData?.waiver && 
        requiredWaiverShowTypes.includes(source) && 
        config?.pages && 
        !config.pages.some((p: { name: string }) => p.name === 'waivers')) {
      
      // Find and update the waiverText element
      const waiverText = waiverJSON.elements[0].elements.find(
        (element: any) => element.name === 'waiverText'
      );

      if (waiverText) {
        const waiver = showData.waiver;
        waiverText.markdown = {
          en: waiver.en,
          es: waiver.es || waiver.en,
          fr: waiver.fr || waiver.en
        };
      }

      // Handle minor waiver text if it exists
      if (showData.minorWaiver) {
        const minorWaiverText = waiverJSON.elements[0].elements.find(
          (element: any) => element.name === 'minorWaiverText'
        );

        if (minorWaiverText) {
          const waiver = showData.minorWaiver;
          minorWaiverText.markdown = {
            en: waiver.en,
            es: waiver.es || waiver.en,
            fr: waiver.fr || waiver.en
          };
        }
      }

      config.pages.push(waiverJSON as any);
    }

    return { config, themeJSON };
  }, [showData?.isCanadian, showData?.isLincoln, showData?.waiver, showData?.showName, source]);

  useEffect(() => {
    surveyConfig?.then(config => setResolvedConfig(config));
  }, [surveyConfig]);

  // Create survey instance
  useEffect(() => {
    if (showData && resolvedConfig) {
      const initSurvey = async () => {
        // Set supported locales before creating survey
        if (showData.isCanadian) {
          surveyLocalization.supportedLocales = ["en", "fr"];

          surveyLocalization.locales["fr"] = {
            pagePrevText: "Précédent",
            pageNextText: "Suivant",
            completeText: "Envoyer",
            requiredError: "Obligatoires",
            invalidEmail: "Veuillez entrer une adresse e-mail valide.",
            textMinLength: "Entrez au moins {0} caractères.",
            textMaxLength: "N'entrez pas plus de {0} caractères.",
            invalidFormat: "Format non valide."
          };
        } else {
          surveyLocalization.supportedLocales = ["en", "es"];

          surveyLocalization.locales["es"] = {
            pagePrevText: "Anterior",
            pageNextText: "Siguiente",
            completeText: "Enviar",
            requiredError: "Obligatorio",
            invalidEmail: "Por favor ingrese un correo electrónico válido",
            textMinLength: "Por favor ingrese al menos {0} caracteres",
            textMaxLength: "Por favor ingrese no más de {0} caracteres",
            invalidFormat: "Formato no válido"
          };
        }

        // Initialize appropriate theme
        if (showData.isLincoln) {
          await import('@/helpers/surveyTemplatesLincoln');
        } else {
          await import('@/helpers/surveyTemplatesFord');
        }

        const themeJSON = showData.isLincoln ? lincolnTheme : fordTheme;

        document.body.style.backgroundColor = themeJSON.cssVariables?.['--sjs-editor-background'] || '#ffffff';
        document.title = showData.showName;

        if (themeJSON.favicon) {
          const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
          if (link) {
            link.href = themeJSON.favicon;
          }
        }

        const survey = new Model(resolvedConfig.config);
        survey.applyTheme(resolvedConfig.themeJSON);

        // Add the CSS classes callback
        survey.onUpdateQuestionCssClasses.add((survey, options) => {
          const { question, cssClasses } = options;

          if (question.name === "signature") {
            cssClasses.root += " signatureInput";
          }
          if (question.name === "minorSignature") {
            cssClasses.root += " signatureInput";
          }
        });

        // Set up event handlers immediately after creating the survey
        prepareForSurvey(survey);
        prepareSurveyOnQuestionAdded(null, { survey });

        survey.onAfterRenderSurvey.add((sender: any) => {
          sender.setValue('device_survey_guid', uuidv4());
          sender.setValue('start_time', new Date());
          sender.setValue('survey_date', new Date());
          sender.setValue('show_id', showData.showID);
          sender.setValue('specialist_id', specialist_id || '');
          sender.setValue('app_version', 'microssite 2.0');
          sender.setValue('abandoned', 0);
          sender.setValue('device_id', window.navigator?.userAgent || '');
          sender.setValue('source', source);
          sender.setValue('_utm', extractUTM());
          sender.setValue('_referrer', window.frames?.top?.document?.referrer);
          sender.setValue('_language', window.navigator?.language);
          sender.setValue('_screenWidth', window.screen?.width);
          sender.setValue('_offset', (new Date()).getTimezoneOffset());
          sender.setValue('_timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
          sender.setValue('isGlobalShow', showData.isGlobalShow);

          const vehicleID = searchParams.get('vehicleID');
          if (vehicleID) {
            sender.setValue('voi', [Number(vehicleID)]);
          }
        });

        survey.onComplete.add(async (sender: Model, options: CompleteEvent) => {
          console.log('*** HANDLE COMPLETE');
          const originalMesage = sender.completedHtml;
          sender.completedHtml = "<h3>Saving...</h3>";
          options.showDataSaving('Saving...');

          let surveyData = sender.data;

          surveyData['end_time'] = new Date();
          surveyData['language'] = language;

          sender.getAllQuestions().forEach(question => {
            surveyData[question.valueName || question.name] = (typeof question.value === 'undefined' || question.value === null) ? null : question.value;
          });

          // Transform data for API
          const apiData = {
            // System/non-UI fields
            show_id: surveyData.show_id,
            start_date: showData.startDate.format('YYYY-MM-DD'),
            end_date: showData.endDate.format('YYYY-MM-DD'),
            specialist_id: surveyData.specialist_id,
            team_id: showData.teamID,
            survey_date: surveyData.survey_date,
            device_survey_guid: surveyData.device_survey_guid,
            device_id: surveyData.device_id,
            survey_type: source,
            app_version: surveyData.app_version,
            abandoned: surveyData.abandoned || 0,
            start_time: surveyData.start_time,
            end_time: surveyData.end_time,

            // Custom data / non FFS questions
            custom_data: JSON.stringify({
              _utm: surveyData._utm,
              _referrer: surveyData._referrer,
              _language: surveyData._language,
              _screenWidth: surveyData._screenWidth,
              _offset: surveyData._offset,
              _timeZone: surveyData._timeZone,
              why_are_you_here: surveyData.why_are_you_here || null,
              shows_attended: surveyData.shows_attended || null,
              preferred_language: surveyData.preferred_language || null,
              highest_level_education: surveyData.highest_level_education || null,
              hispanic_latino: surveyData.hispanic_latino || null,
              ethnicity_race: surveyData.ethnicity_race?.includes('none') ? ['Prefer not to answer'] : (surveyData.ethnicity_race?.length ? surveyData.ethnicity_race : null),
              device_name: surveyData.device_name || null,
              minor_name_1: surveyData.minorName1 || null,
              minor_name_2: surveyData.minorName2 || null,
              minor_name_3: surveyData.minorName3 || null
            }),

            // Personal info
            first_name: surveyData.first_name,
            last_name: surveyData.last_name,
            business_name: surveyData.business_name || null,
            address1: surveyData.address_group?.address1,
            address2: null,
            city: surveyData.address_group?.city,
            state: surveyData.address_group?.state,
            country_code: showData.isCanadian ? 'CAN' : 'USA',
            zip_code: surveyData.address_group?.zip_code,

            // Contact info
            phone: surveyData.phone,
            email: surveyData.email,
            email_opt_in: surveyData.fordEmailOptIn ? 1 : 0,
            accepts_sms: 0,
            gender: surveyData.gender || null,
            age_bracket: surveyData.ageBracket || null,

            // Other fields
            vehicle_driven_most_make_id: surveyData.vehicleDrivenMostMake || null,
            in_market_timing: surveyData.inMarketTiming || null,
            personal_or_business: surveyData.personal_or_business || null,
            language: surveyData.language,
            industry: surveyData.industry || null,

            // Signature/Waiver
            waiver_type: source,
            signature: surveyData.signature || null,
            minor_signature: surveyData.minorSignature || null,

            // Vehicles of interest
            vehicles_of_interest: surveyData.voi || [],
          };

          console.log('*** apiData', apiData);

          // call the API
          surveyService.saveMicrosite(apiData)
            .then((data) => {
              console.log('API Response:', data);
              sender.completedHtml = originalMesage;
              options.showDataSavingSuccess();
              navigate(`/success`, {
                state: {
                  team_id: showData.teamID,
                  showData: {
                    teamID: showData.teamID,
                    isLincoln: showData.isLincoln,
                    isCanadian: showData.isCanadian,
                    showName: showData.showName
                  },
                  result: {
                    pin: data.pin,
                    waiverSigned: !!surveyData.signature,
                    minorsCount: surveyData.minorsYesNo === "1"
                      ? [surveyData.minorName1, surveyData.minorName2, surveyData.minorName3]
                        .filter(Boolean).length
                      : 0
                  }
                }
              });
            })
            .catch(err => {
              const error = err instanceof AxiosError ? err : createError(err.toString());
              console.error('Error details:', {
                message: error.message,
                stack: error.stack,
                response: error.response
              });
              sender.completedHtml = "<h3>Error saving survey data</h3>";
              options.showDataSavingError();
            });
        });

        setThisSurvey(survey);
      };

      initSurvey();
    }
  }, [showData, setLanguage, resolvedConfig]);

  useEffect(() => {
    if (thisSurvey) {
      thisSurvey.locale = language;
      thisSurvey.render();
    }
  }, [thisSurvey, language]);

  useEffect(() => {
    if (!thisSurvey || !showData) return;

    const handlePageChanged = () => {
      setCurrentPage(thisSurvey.currentPageNo);
    };

    thisSurvey.onCurrentPageChanged.add(handlePageChanged);

    return () => {
      thisSurvey.onCurrentPageChanged.remove(handlePageChanged);
    };
  }, [thisSurvey]);

  useEffect(() => {
    if (thisSurvey) {
      // Expose survey instance to window
      (window as any).survey = thisSurvey;

      // Add custom functions to survey
      thisSurvey.consentAccept = () => {
        thisSurvey.nextPage();
      };

      thisSurvey.consentReject = async () => {
        if (!showData) return;

        // Set required values indicating consent rejection
        thisSurvey.setValue('abandoned', 1);
        thisSurvey.setValue('first_name', 'ABANDONED');
        thisSurvey.setValue('country_code', 'CAN');

        try {
          // Prepare apiData with necessary fields
          const apiData = {
            // System fields
            show_id: thisSurvey.getValue('show_id'),
            start_date: showData.startDate.format('YYYY-MM-DD'),
            end_date: showData.endDate.format('YYYY-MM-DD'),
            specialist_id: thisSurvey.getValue('specialist_id') || '',
            team_id: showData.teamID,
            survey_date: thisSurvey.getValue('survey_date'),
            device_survey_guid: thisSurvey.getValue('device_survey_guid'),
            device_id: thisSurvey.getValue('device_id'),
            survey_type: thisSurvey.getValue('source'),
            app_version: thisSurvey.getValue('app_version'),
            abandoned: 1,
            start_time: thisSurvey.getValue('start_time'),
            end_time: new Date(),

            // Required fields
            first_name: 'ABANDONED',
            last_name: '',
            business_name: null,
            address1: '',
            address2: null,
            city: '',
            state: '',
            country_code: 'CAN',
            zip_code: '',
            phone: null,
            email: '',
            email_opt_in: 0,
            accepts_sms: 0,
            gender: null,
            age_bracket: null,
            vehicle_driven_most_make_id: null,
            in_market_timing: null,
            personal_or_business: null,
            language: thisSurvey.locale,
            industry: null,
            waiver_type: thisSurvey.getValue('source'),
            signature: null,
            minor_signature: null,
            custom_data: JSON.stringify({
              _utm: thisSurvey.getValue('_utm'),
              _referrer: thisSurvey.getValue('_referrer'),
              _language: thisSurvey.getValue('_language'),
              _screenWidth: thisSurvey.getValue('_screenWidth'),
              _offset: thisSurvey.getValue('_offset'),
              _timeZone: thisSurvey.getValue('_timeZone'),
            }),
            vehicles_of_interest: []
          };

          // Save the survey data via the API
          const response = await surveyService.saveMicrosite(apiData);

          // Navigate to the Success page, passing the PIN and other relevant data
          navigate('/success', {
            state: {
              team_id: showData.teamID,
              showData: {
                teamID: showData.teamID,
                isLincoln: showData.isLincoln,
                isCanadian: showData.isCanadian,
                showName: showData.showName
              },
              result: {
                pin: response.pin, // Assuming the API returns a 'pin' field
                waiverSigned: false,
                minorsCount: 0
              }
            }
          });
        } catch (error) {
          console.error('Error saving rejected survey:', error);
          // Optionally, navigate to an error page or display an error message within the UI
          navigate('/error', {
            state: {
              message: "There was an error processing your request. Please try again later."
            }
          });
        }
      };

      // Hide previous button on personInfoCAN page
      thisSurvey.onCurrentPageChanged.add((sender: any) => {
        if (sender.currentPage.name === "personInfoCAN") {
          sender.showPrevButton = false;
        } else {
          sender.showPrevButton = true;
        }
      });
    }
  }, [thisSurvey, t]);

  if (loading) return <SurveySkeleton />;
  if (error) {
    if (error.code === "ECONNABORTED") {
      return (
        <div className="max-w-3xl mx-auto px-4 py-8 text-center">
          <h1 className="text-2xl font-bold mb-4">Connection Timeout</h1>
          <p className="mb-4">The server is taking longer than expected to respond. This could be due to a slow internet connection.</p>
          <p>Please check your connection and try again.</p>
          <Button onClick={() => window.location.reload()} className="mt-6">
            Retry
          </Button>
        </div>
      );
    }
    return (
      <div className="max-w-3xl mx-auto px-4 py-8 text-center">
        <h1 className="text-2xl font-bold mb-4">Error</h1>
        <p>{error.message || "An unexpected error occurred"}</p>
        <Button onClick={() => window.location.reload()} className="mt-6">
          Retry
        </Button>
      </div>
    );
  }
  if (!showData || !thisSurvey) return null;

  const backgroundImage = showData.isLincoln
    ? {
      desktop: nautilus2160,
      tablet: nautilus960,
    }
    : {
      desktop: expedition2160,
      tablet: expedition960,
    };

  // Add the Lincoln-specific class to the root element if it's Lincoln
  if (showData.isLincoln) {
    document.body.classList.add('lincoln-survey');
  }

  return (
    <>
      <div className="w-full md:w-[60%] xl:w-1/2 px-4 py-2">
        <h1 className={`text-2xl font-bold ${showData.isLincoln ? 'text-lincoln-black' : 'text-ford-blue'}`}>
          {showData.showName}
        </h1>

        {!showData.isCanadian && (
          <>
            <div className="flex items-center mt-4">
              {thisSurvey.pages.map((_, index) => (
                <div key={index} className="flex items-center">
                  <div className={`
                    w-6 h-6 md:w-8 md:h-8 rounded-full flex items-center justify-center border-2 text-sm md:text-base
                    ${currentPage === index
                      ? 'bg-[var(--semantic-color-fill-onlight-interactive)] text-white border-[var(--semantic-color-fill-onlight-interactive)]'
                      : currentPage > index
                        ? 'bg-white text-[var(--semantic-color-fill-onlight-interactive)] border-[var(--semantic-color-fill-onlight-interactive)]'
                        : 'bg-white text-gray-500 border-gray-300'
                    }
                  `}>
                    {currentPage > index ? '✓' : index + 1}
                  </div>
                  {index < thisSurvey.pages.length - 1 && (
                    <div className={`h-[2px] w-8 md:w-16 mx-1 ${currentPage > index
                        ? 'bg-[var(--semantic-color-fill-onlight-interactive)]'
                        : 'bg-gray-300'
                      }`} />
                  )}
                </div>
              ))}
            </div>
            <div className="mt-2 text-sm text-gray-600">
              {t('pageXofY').replace('{0}', (currentPage + 1).toString()).replace('{1}', thisSurvey.pages.length.toString())}
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col md:flex-row min-h-screen">
        <div className="w-full md:w-[60%] xl:w-1/2">
          <SurveyComponent model={thisSurvey} />
        </div>

        {/* Background Image - Hidden on mobile */}
        <div className={`hidden md:block md:w-[40%] xl:w-1/2 pl-0 pr-8 pt-[52px] ${showData.isLincoln ? 'gdux-lincoln' : 'gdux-ford'}`}>
          <div className="w-full h-[calc(100vh-4rem)]">
            {/* Desktop background - hidden until xl breakpoint */}
            <img
              src={backgroundImage.desktop}
              alt=""
              className="hidden xl:block w-full object-contain rounded-xl"
            />
            {/* Tablet-specific background - shown between md and xl breakpoints */}
            <img
              src={backgroundImage.tablet}
              alt=""
              className="md:block xl:hidden w-full object-contain rounded-xl"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Survey;
